import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useCallback } from 'react'
import { getSearchParams } from '@tmap-web-lib/utils'
import Url from 'url-parse'
import isEmpty from 'lodash-es/isEmpty'
import { PageIdSearchParams } from '../providers/page-id-context'
import mixpanel from 'mixpanel-browser'
import { RoutePath } from '../routes'

function useOpenServiceUrl() {
  return useCallback((srcUrl: string, openAnotherService?: string) => {
    if (!srcUrl) {
      return
    }

    const env = window.location.href.split('.')[0]
    let path

    // 메인 페이지에서 컨텐츠 배너 클릭 접근
    if (srcUrl.startsWith('tmap')) {
      const urlObject = new Url(srcUrl)
      const params = getSearchParams(urlObject.query || '')
      const searchParams = getSearchParams<PageIdSearchParams>()
      const { pageid, ...paramsExceptPageid } = searchParams
      const scrollToObj = { scroll_to: params.extra && JSON.parse(params.extra).scroll_to }
      const seqObj = { seq: params.extra && JSON.parse(params.extra).seq }
      const moveToObj = { move_to: params.extra && JSON.parse(params.extra).move_to }
      const defaultParams = `&extra=${encodeURIComponent(JSON.stringify({
        is_first_history: true,
        logbox: { origin: 'tab', type: 'tab' },
        ...scrollToObj,
        ...seqObj,
        ...moveToObj,
      }))}`
      const extraParams = `extra=${encodeURIComponent(JSON.stringify({
        ...JSON.parse(paramsExceptPageid.extra || '{}'),
        ...scrollToObj,
        ...seqObj,
        ...moveToObj,
      }))}`
      const inDrivingLifeSearchParams = isEmpty(searchParams.extra) ? defaultParams : extraParams

      switch (params.pageid) {
        case 'benefit':
          path = `${RoutePath.Benefit}?${inDrivingLifeSearchParams}`
          break
        case 'benefit_popular':
          path = `${RoutePath.Benefit_popular}?${inDrivingLifeSearchParams}`
          break
        case 'benefit_detail':
          path = `${RoutePath.Benefit_detail}?${inDrivingLifeSearchParams}`
          break
        case 'driving_analysis':
          path = `${RoutePath.Driving_analysis}?${inDrivingLifeSearchParams}`
          break
        case 'vehicle':
          path = `${RoutePath.Vehicle}?${inDrivingLifeSearchParams}`
          break
        case 'vehicle_setting':
          path = `${RoutePath.Vehicle_setting}?${inDrivingLifeSearchParams}`
          break
        case 'vehicle_detail':
          path = `${RoutePath.Vehicle_detail}?${inDrivingLifeSearchParams}`
          break
        case 'driving_history':
          path = `${RoutePath.History}?${inDrivingLifeSearchParams}`
          break
        case 'driving_history_detail':
          path = `${RoutePath.History_detail}?${inDrivingLifeSearchParams}`
          break
        case 'etc_insurer_contact':
          path = `${RoutePath.Etc_insurer_contact}?${inDrivingLifeSearchParams}`
          break
        case 'etc_emergency_call':
          path = `${RoutePath.Etc_emergency_call}?${inDrivingLifeSearchParams}`
          break
        case 'etc_terms':
          path = `${RoutePath.Etc_terms}?${inDrivingLifeSearchParams}`
          break
        case 'settings':
          path = `${RoutePath.Settings}?${inDrivingLifeSearchParams}`
          break
        case 'settings_otp':
          path = `${RoutePath.Settings_otp}?${inDrivingLifeSearchParams}`
          break
        case 'settings_withdrawal':
          path = `${RoutePath.Settings_withdrawal}?${inDrivingLifeSearchParams}`
          break
        case 'unpaid_toll':
          path = `${RoutePath.Unpaid_toll}?${inDrivingLifeSearchParams}`
          break
        default:
          path = ''
          break
      }
    } else {
      path = srcUrl
    }

    if (openAnotherService === 'carLife') {
      TmapApp.openServiceByUrl({
        url: `https://m.tmap.co.kr${path}`,
        title: '',
        cacheControl: true,
        portraitonly: true
      })
    } else if (openAnotherService === 'terms') {
      const href = window.location.href

      if (href.startsWith('https://tscore-dev.')) {
        TmapApp.openServiceByUrl({
          url: `https://dev.tmap.co.kr/tmap2/m/app${path}`, title: '', cacheControl: true, portraitonly: true
        })
      } else if (href.startsWith('https://tscore-dtg.') || href.startsWith('https://tscore-stg.')) {
        TmapApp.openServiceByUrl({
          url: `https://stg.tmap.co.kr/tmap2/m/app${path}`, title: '', cacheControl: true, portraitonly: true
        })
      } else {
        TmapApp.openServiceByUrl({
          url: `https://tmap.co.kr/tmap2/m/app${path}`, title: '', cacheControl: true, portraitonly: true
        })
      }
    } else {
      if (path) {
        TmapApp.openServiceByUrl({
          url: `${env}.tmobiweb.com${path}`, title: '', cacheControl: true, portraitonly: true
        })
      } else {
        TmapApp.makeToast({ msg: '일시적인 접속 오류로 복구 중입니다.' })
        mixpanel.track(`tscore:잘못된 경로로 새 웹뷰 띄움 : ${srcUrl}`)
      }
    }
  }, [])
}

export { useOpenServiceUrl }
